import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseLaptop, faBookOpen, faUmbrellaBeach, faPerson } from '@fortawesome/free-solid-svg-icons';
import { FaAward, FaMoneyCheckAlt, FaBriefcase } from "react-icons/fa";
import { useEffect, useState } from "react";

import './career.css';
import Modal from "../../compnent/modal/Modal";


const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function Career() {

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const formFields = [
        { label: "Your Name", type: "text", name: "name", placeholder: "Your Name" },
        { label: "Your Email", type: "email", name: "email", placeholder: "Your Email" },
        { label: "Contact No", type: "number", name: "contact", placeholder: "Contact No" },
        { label: "Application message", type: "textarea", name: "message", placeholder: "Application message" },
        // { label: "Upload Resume", type: "file", name: "resume", placeholder: "Upload your resume" },

    ];

    // const handleModalSubmit = (data) => {
    //     console.log("Form Data Submitted:", data);
    //     setModalOpen(false);
    // };
    
    const handleModalOpen = (data) => {
        console.log("Form Data Submitted:", data);
        setModalOpen(true);
    };
     


    const jobs = [
        {
            job_id: "J001",
            job_title: "Full Stack Developer",
            technologies: ["JavaScript", "Node.js", "React", "MongoDB"],
            location: "kolkata, India",
            experience: "3+ years",
            key_skills: ["Front-end and back-end development", "API development", "Version control (Git)", "Agile methodologies"],
            description: "As a Full Stack Developer, you will be responsible for developing both the front-end and back-end of web applications. You'll collaborate with cross-functional teams to deliver high-quality software solutions."
        },
        {
            job_id: "J002",
            job_title: "AI/ML Engineer",
            technologies: ["Python", "TensorFlow", "PyTorch", "scikit-learn"],
            location: "Kolkata, India",
            experience: "4+ years",
            key_skills: ["Machine learning model development", "Data analysis", "Deep learning", "NLP"],
            description: "The AI/ML Engineer will design and build machine learning models to solve complex problems. You will work with large datasets and state-of-the-art AI frameworks to create predictive algorithms."
        },
        {
            job_id: "J003",
            job_title: "Data Scientist",
            technologies: ["Python", "R", "SQL", "Big Data (Hadoop, Spark)"],
            location: "Kolkata, India",

            experience: "5+ years",
            key_skills: ["Data modeling", "Statistical analysis", "Predictive analytics", "Data visualization"],
            description: "The Data Scientist will be responsible for analyzing large data sets to provide actionable insights for business decisions. You will work on developing predictive models and data visualization tools."
        },
        {
            job_id: "J004",
            job_title: "Mobile App Developer",
            technologies: ["Swift", "Kotlin", "React Native"],
            location: "Kolkata, India",
            experience: "3+ years",
            key_skills: ["iOS and Android development", "Cross-platform app development", "UI/UX optimization", "API integration"],
            description: "As a Mobile App Developer, you will create cross-platform mobile applications for iOS and Android. You will optimize the user experience and ensure smooth API integration."
        },
        {
            job_id: "J005",
            job_title: "DevOps Engineer",
            technologies: ["AWS", "Docker", "Kubernetes", "CI/CD"],
            location: "Kolkata, India",

            experience: "5+ years",
            key_skills: ["Cloud infrastructure", "Automation", "System monitoring", "Deployment pipelines"],
            description: "The DevOps Engineer will manage cloud infrastructure and build automated deployment pipelines. You will ensure system scalability, security, and reliability through continuous integration and delivery."
        },
        {
            job_id: "J006",
            job_title: "Frontend Developer",
            technologies: ["HTML", "CSS", "JavaScript", "Vue.js"],
            location: "Kolkata, India",

            experience: "2+ years",
            key_skills: ["Responsive design", "Web performance optimization", "User interface design", "Version control (Git)"],
            description: "As a Frontend Developer, you will create responsive and high-performing web interfaces using modern front-end technologies. You will collaborate with designers and back-end developers to deliver seamless user experiences."
        },
        {
            job_id: "J007",
            job_title: "Backend Developer",
            technologies: ["Node.js", "Express", "MySQL", "MongoDB"],
            location: "Kolkata, India",

            experience: "4+ years",
            key_skills: ["Server-side logic", "Database management", "API development", "Security best practices"],
            description: "The Backend Developer will be responsible for server-side application logic and database management. You will design and develop secure, scalable APIs to support the front-end team."
        },
        {
            job_id: "J008",
            job_title: "Cloud Architect",
            technologies: ["AWS", "Azure", "Google Cloud", "Terraform"],
            location: "Kolkata, India",

            experience: "6+ years",
            key_skills: ["Cloud architecture design", "Migration strategies", "Cost optimization", "Security and compliance"],
            description: "As a Cloud Architect, you will design cloud infrastructure solutions and guide the migration of existing systems to the cloud. You will focus on security, scalability, and cost optimization."
        },
        {
            job_id: "J009",
            job_title: "Machine Learning Researcher",
            technologies: ["Python", "Deep Learning frameworks", "MATLAB"],
            location: "Kolkata, India",

            experience: "3+ years",
            key_skills: ["Research in AI and ML", "Algorithm development", "Model evaluation", "Data processing"],
            description: "The Machine Learning Researcher will conduct cutting-edge research in AI/ML to advance state-of-the-art technologies. You will focus on developing new algorithms and improving model accuracy."
        },
        {
            job_id: "J010",
            job_title: "Blockchain Developer",
            technologies: ["Solidity", "Ethereum", "Hyperledger", "Smart Contracts"],
            location: "Kolkata, India",

            experience: "3+ years",
            key_skills: ["Smart contract development", "Blockchain architecture", "Security protocols", "Cryptography"],
            description: "As a Blockchain Developer, you will design and implement decentralized applications on the Ethereum network and other blockchain platforms. You will also develop secure smart contracts and blockchain protocols."
        },
        {
            job_id: "J011",
            job_title: "HR Manager",
            location: "Kolkata, India",

            experience: "5+ years",
            key_skills: ["Employee relations", "Recruitment", "HR policies", "Performance management"],
            description: "The HR Manager will manage recruitment processes, employee relations, and performance evaluations. You will implement HR policies and work on employee engagement initiatives."
        },
        {
            job_id: "J012",
            job_title: "HR Business Partner",
            location: "Kolkata, India",

            experience: "6+ years",
            key_skills: ["Strategic HR planning", "Employee engagement", "Talent management", "HR analytics"],
            description: "As an HR Business Partner, you will work closely with senior management to develop and implement strategic HR initiatives. You will focus on talent management, employee engagement, and HR analytics."
        },
        {
            job_id: "J013",
            job_title: "Business Development Manager",
            location: "Abu Dhabi , UAE",
            experience: "4+ years",
            key_skills: ["Sales strategy", "Client relationship management", "Market analysis", "Lead generation"],
            description: "The Business Development Manager will focus on expanding the company's client base, building strong client relationships, and identifying new business opportunities in global markets."
        },
        {
            job_id: "J014",
            job_title: "Sales Executive",
            location: "Kolkata, India",
            experience: "3+ years",
            key_skills: ["B2B sales", "Cold calling", "Account management", "Lead generation"],
            description: "As a Sales Executive, you will be responsible for identifying and closing new sales opportunities. You will focus on generating leads, managing accounts, and driving revenue growth."
        },
        {
            job_id: "J015",
            job_title: "Business Development Director",
            location: "Abu Dhabi, UAE",
            experience: "7+ years",
            key_skills: ["Sales leadership", "Revenue generation", "Strategic partnerships", "Negotiation"],
            description: "The Business Development Director will lead the company's growth efforts by driving sales strategy, building strategic partnerships, and leading high-level negotiations with key clients."
        },
        {
            job_id: "J016",
            job_title: "Account Executive",
            location: "Kolkata, India",

            experience: "4+ years",
            key_skills: ["Client management", "Sales pipeline management", "Customer relationship building", "Target-driven"],
            description: "As an Account Executive, you will manage client relationships, oversee the sales pipeline, and work closely with customers to ensure satisfaction and repeat business."
        },
        {
            job_id: "J017",
            job_title: "Sales and Marketing Manager",
            location: "San Francisco, USA",
            experience: "5+ years",
            key_skills: ["Sales strategy", "Marketing campaigns", "Brand management", "Digital marketing"],
            description: "The Sales and Marketing Manager will be responsible for developing sales strategies and marketing campaigns to increase brand awareness and drive revenue growth."
        }
    ];

    const handleSearch = (e) => setSearchTerm(e.target.value);
    const handleDepartmentChange = (e) => setSelectedDepartment(e.target.value);
    const handleCountryChange = (e) => setSelectedCountry(e.target.value);
    const handleCityChange = (e) => setSelectedCity(e.target.value);
    // const handleApplyNow = (jobId) => {
    //     // You can implement your logic here for applying, like redirecting or showing a modal
    //     // alert(`Applying for job with ID: ${jobId}`);
    //     setIsModalOpen(true);
    // };

    const filteredJobs = jobs.filter((job) => {
        const matchesSearch = job.job_title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDepartment = selectedDepartment === "" ||
            (selectedDepartment === "Development" && ["Full Stack Developer", "Frontend Developer", "Backend Developer", "Mobile App Developer", "DevOps Engineer", "Cloud Architect", "AI/ML Engineer", "Machine Learning Researcher", "Blockchain Developer", "Data Scientist"].includes(job.job_title)) ||
            (selectedDepartment === "HR" && ["HR Manager", "HR Business Partner"].includes(job.job_title)) ||
            (selectedDepartment === "Sales" && ["Business Development Manager", "Sales Executive", "Account Executive", "Business Development Director"].includes(job.job_title)) ||
            (selectedDepartment === "Marketing" && job.job_title === "Sales and Marketing Manager");

        const matchesCity = selectedCity === "" || job.location.toLowerCase().includes(selectedCity.toLowerCase());

        return matchesSearch && matchesDepartment && matchesCity;
    });


    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <>


            <div className="container-fluid py-5 " style={{ marginTop: '4%' }} >
                <div className="container ">

                    <div className="row g-5 mb-5  align-items-center">
                        <div className="col-lg-6  wow zoomIn" data-wow-delay="0.5s">

                            <div className="image-section">
                                <img className="employee-image" src="img/cr.png" />
                            </div>
                        </div>
                        <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">

                            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                            <div className="services_heading mb-4">Join us and work for <span className='highlight'>Global Companies</span></div>
                            <div className="services_subheading mb-4">   Here in WS, we have fun at work. But when it comes to client
                                deliverables, there is no compromise. We work hard and pride ourselves
                                in providing clients with flawless work within the deadline. Over the
                                last 2 decades Web Spiders Group has the reputation of being a fair
                                company for all stakeholders - and providing some of the most
                                innovative and cutting edge work and projects.</div>

                        </div>
                    </div>

                    <div className="row g-5 mb-5  align-items-center">
                        {/* Benefits Section */}
                        <div className="col-md-6 custom-box">

                            <div style={styles.heading}>Benefits</div>

                            <div style={styles.item}>
                                <FontAwesomeIcon icon={faHouseLaptop} style={styles.icon} />
                                <div>
                                    <div style={styles.title}>Remote first Roles</div>
                                    <div className="services_subheading">All roles are remote first and involve either 100% work-from-home or hybrid.</div>
                                </div>
                            </div>

                            <div style={styles.item}>
                                <FontAwesomeIcon icon={faBookOpen} style={styles.icon} />
                                <div>
                                    <div style={styles.title}>Training Room and Library</div>
                                    <div className="services_subheading">
                                        We have a training room and a library with an extensive range of books, magazines and electronic resources available for advanced learning and development.
                                    </div>
                                </div>
                            </div>

                            <div style={styles.item}>
                                <FontAwesomeIcon icon={faUmbrellaBeach} style={styles.icon} />
                                <div>
                                    <div style={styles.title}>Paid and Sick Leave - 20 Days</div>
                                    <div className="services_subheading">
                                        Apart from standard public holidays, employees are entitled to the leave structure after 4 months of their tenure.
                                    </div>
                                </div>
                            </div>

                            <div style={styles.item}>
                                <FontAwesomeIcon icon={faPerson} style={styles.icon} />
                                <div>
                                    <div style={styles.title}>Complementary Yoga Session at Office</div>
                                    <div className="services_subheading">You are entitled to enjoy free yoga training sessions, twice a week.</div>
                                </div>
                            </div>
                        </div>


                        {/* Image Section */}
                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>
                        <div className="benefits-container">
                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <FaAward className="mb-3 fa-2x " /> {/* Replaced Font Awesome icon */}
                                </div>
                                <div style={styles.title}>Awards for Excellence</div>
                                <div className="services_subheading">
                                    No good deed goes unrewarded at Web Spiders. Excellent performance is officially recognized with Awards for Excellence and Certificates of Merit at our Annual Town Hall Meets.
                                </div>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <FaMoneyCheckAlt className="mb-3 fa-2x " /> {/* Replaced Font Awesome icon */}
                                </div>
                                <div style={styles.title}>Financial Benefits</div>
                                <div className="services_subheading">
                                    After 5 years of continuous service, the employees become eligible for gratuity. It is given @ 15 days basic salary for every completed year of service. Free Tax consulting through our Chartered Accountants.
                                </div>
                            </div>

                            <div className="benefit-card">
                                <div className="benefit-icon">
                                    <FaBriefcase className="mb-3 fa-2x " /> {/* Replaced Font Awesome icon */}
                                </div>
                                <div style={styles.title}>On-the-job Benefits and Perks</div>
                                <div className="services_subheading">
                                    Trainings, project win lunches and parties, complimentary breakfast at HQ and evening snacks, buddy referral programs, paid and sick leave besides bank holidays, maternity leave, special bereavement benefit, relocation support – domestic and international.
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row g-3 mb-5 align-items-center">
                        <div className="col-lg-4 col-md-6">
                            <i className="fas fa-search position-absolute" style={{ left: '12%', marginTop: "20px", transform: 'translateY(-50%)', color: '#999' }}></i>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search for jobs..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <select className="form-select" value={selectedDepartment} onChange={handleDepartmentChange}>
                                <option value="">Choose Department</option>
                                <option value="Development">Development</option>
                                <option value="Marketing">Marketing</option>
                                <option value="HR">Human Resources</option>
                                <option value="Sales">Sales</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <select className="form-select" value={selectedCity} onChange={handleCityChange}>
                                <option value="">Select City</option>
                                <option value="Kolkata, India">Kolkata, India</option>
                                <option value="San Francisco, USA">San Francisco, USA</option>
                                <option value="Abu Dhabi, UAE">Abu Dhabi, UAE</option>
                            </select>

                        </div>
                    </div>

                    <div className="row g-3 mb-5 align-items-center">
                        <div className="job-list">
                            {filteredJobs.length > 0 ? (
                                filteredJobs.map((job) => (
                                    <div className="job-card" key={job.job_id}>
                                        <div className="subheading mb-2" >{job.job_title}</div>
                                        <div className="services_subheading mb-1"><strong>Description:</strong> {job.description}</div>
                                        <div className="services_subheading mb-1"><strong>Location:</strong> {job.location}</div>
                                        <div className="services_subheading mb-1"><strong>Experience:</strong> {job.experience}</div>
                                        <div className="services_subheading mb-1"><strong>Technologies:</strong> {job.technologies ? job.technologies.join(', ') : 'N/A'}</div>
                                        <div className="services_subheading mb-1"><strong>Key Skills:</strong> {job.key_skills ? job.key_skills.join(', ') : 'N/A'}</div>
                                        <button onClick={() => handleModalOpen(job.job_id)} className="apply-button">
                                            Apply Now
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p>No jobs found matching your criteria.</p>
                            )}
                        </div>
                        {/* Modal component */}
                        <Modal isOpen={isModalOpen}
                            onClose={() => setModalOpen(false)}
                            formFields={formFields}
                            // onSubmit={handleModalSubmit} 
                            />
                    </div>


                </div>
            </div>

        </>
    )
}

const styles = {
    container: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '20px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '30px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0 0 5px 0',
    },
    description: {
        fontSize: '15px',
        color: '#666',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};