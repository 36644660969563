import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import './our_product2.css';
import Footer from '../../common/footer/Footer';

gsap.registerPlugin(ScrollTrigger);

const Our_product2 = () => {
  useEffect(() => {
    const pageContainer = document.querySelector(".product_container");
    const pinWrap = document.querySelector(".pin-wrap");

    const scroller = new LocomotiveScroll({
      el: pageContainer,
      smooth: true,
      smartphone: { smooth: true },
      tablet: { smooth: true }
    });

    scroller.on("scroll", (obj) => {
      // Store scroll position in sessionStorage
      sessionStorage.setItem('scrollPosition', obj.scroll.y);
      ScrollTrigger.update();
    });

    // Restore scroll position
    const storedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (storedScrollPosition) {
      scroller.scrollTo(parseFloat(storedScrollPosition));
    }

    ScrollTrigger.scrollerProxy(pageContainer, {
      scrollTop(value) {
        return arguments.length
          ? scroller.scrollTo(value, 0, 0)
          : scroller.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight
        };
      },
      pinType: pageContainer.style.transform ? "transform" : "fixed"
    });

    const pinScroll = () => {
      const pinWrapWidth = pinWrap.scrollWidth;
      const horizontalScrollLength = pinWrapWidth - window.innerWidth;

      gsap.to(pinWrap, {
        x: -horizontalScrollLength,
        ease: "none",
        scrollTrigger: {
          scroller: pageContainer,
          trigger: ".horizontal-section",
          start: "top top",
          end: () => `+=${pinWrapWidth}`,
          scrub: true,
          pin: true,
          markers: false,
          invalidateOnRefresh: true,
          onEnter: () => scroller.update(),
          onLeaveBack: () => scroller.update(),
        }
      });

      ScrollTrigger.refresh();
    };

    pinScroll();

    // Update on window resize
    const handleResize = () => {
      pinScroll(); // Recalculate the scroll on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      scroller.destroy();
    };
  }, []);

  return (
    <div className="product_container">
      <Footer />
      <Footer />
      <Footer />

      <div className="container">
        <div className="horizontal-section">
          <div className='pin-wrap'>
            <div className='services_heading'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
            <img src="https://images.pexels.com/photos/5207262/pexels-photo-5207262.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=900" alt="Product 1" />
            <img src="https://images.pexels.com/photos/3371358/pexels-photo-3371358.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=900" alt="Product 2" />
            <img src="https://images.pexels.com/photos/3618545/pexels-photo-3618545.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=900" alt="Product 3" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Our_product2;
