import React, { useRef, useState } from 'react';
import './launch_product_videos.css';

const Launch_product_video = () => {
    const videoRef = useRef(null); // Create a reference for the video element
    const [hasPlayed, setHasPlayed] = useState(false); // State to track if the video has been played

    // Function to handle the video play event
    const handleVideoPlay = () => {
        if (videoRef.current) {
            videoRef.current.play(); // Play the video
            setHasPlayed(true); // Update state to hide the play button after the first play
        }
    };

    return (
        <div>
            <div className="container-xxl py-2 ">
                <div className="container px-lg-5">
                    <div className="position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="services_heading mt-2">Recently Launched Our AI <span className='highlight'>Tools</span></div>
                        <div className='services_subheading'>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</div>
                        <span className="y-us-title-border"></span>
                    </div>
                </div>
            </div>
            <div className="container-xxl py-2 px-0 wow zoomIn" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="p-5">
                            <div className="services_heading text-uppercase mb-2">Linkometic</div>
                            <div className="services_heading mb-2">Our <span className='highlight'>Linkometic</span> App</div>
                            <div className="services_subheading mb-4">
                                LiNKoMaTiC streamlines link building with AI-driven tools for bulk Google scraping, relevant site ranking, and efficient team management.
                                Tailored for agencies, it empowers link builders to work faster and more effectively.
                            </div>
                            <a href="https://linkomatic.ai/" target="_blank" rel="noopener noreferrer" className="btn cta-button text-white py-md-3 px-md-5 me-3">Read More</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="video_it position-relative"> {/* Added position-relative for overlay */}
                            {!hasPlayed && ( // Show the play button only the first time
                                <button type="button" className="btn-play" onClick={handleVideoPlay}>
                                    <span></span>
                                </button>
                            )}
                            <div className="ratio ratio-16x9">
                                <video 
                                    ref={videoRef} 
                                    controls 
                                    poster="img/video.png" // Add the thumbnail image here
                                >
                                    <source src="img/big_tool.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Launch_product_video;
