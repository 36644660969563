import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaTwitter, FaFacebookF, FaYoutube, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { BiArrowToTop  } from 'react-icons/bi';
import { IoIosArrowForward } from "react-icons/io";
import './footer.css';


export default function Footer() {
  return (
    <>
      <div className="container-fluid bg-dark  position-relative text-white-50 footer overlay-top pt-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3">
              <Link to="/" style={{textDecoration:"none"}}>
                <div className="footer_title mb-3">Robo Analytics</div>
              </Link>
              <div className="footer_text">
                Robo Analytics is a cutting-edge technology company specializing in data analytics and robotics. We empower businesses to make data-driven decisions for a smarter future.
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer_title mb-3">Get In Touch</div>
              <div><FaMapMarkerAlt className=" footer_text me-3" /> 26 No. Binodini Avenue, Kolkata - 700157</div>
              <div><FaPhoneAlt className="footer_text me-3" /> +91 6290 382 261</div>
              <div><FaEnvelope className="footer_text me-3" /> hr.roboanalytics@gmail.com</div>
              <div className="d-flex pt-2">
                <Link className="btn btn-outline-light btn-social" to=""><FaTwitter /></Link>
                <Link className="btn btn-outline-light btn-social" to=""><FaFacebookF /></Link>
                <Link className="btn btn-outline-light btn-social" to=""><FaYoutube /></Link>
                <Link className="btn btn-outline-light btn-social" to=""><FaInstagram /></Link>
                <Link className="btn btn-outline-light btn-social" to=""><FaLinkedinIn /></Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer_title mb-3">Popular Links</div>
              <Link className="btn btn-link" to="">
               <IoIosArrowForward />  About Us
              </Link>
              <Link className="btn btn-link" to="">
               <IoIosArrowForward />  Contact Us
              </Link>
              <Link className="btn btn-link" to="">
                 <IoIosArrowForward />Privacy Policy
              </Link>
              <Link className="btn btn-link" to="">
               <IoIosArrowForward /> Terms & Condition 
              </Link>
              <Link className="btn btn-link" to="">
               <IoIosArrowForward /> Career 
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="footer_title mb-3">Our Ventures</div>
              <Link className="btn btn-link" to="/ai">   <IoIosArrowForward />AI Research and Development</Link>
              <Link className="btn btn-link" to="/cloud-solutions">   <IoIosArrowForward />Cloud Solutions</Link>
              <Link className="btn btn-link" to="/mobile-app">   <IoIosArrowForward />Mobile App Development</Link>
              <Link className="btn btn-link" to="/ui-ux">   <IoIosArrowForward />UX/UI Design</Link>
              <Link className="btn btn-link" to="/web-development">   <IoIosArrowForward />Web & Software Development</Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <Link className="border-bottom" to="#">Robo Analytics</Link>, All Rights Reserved.
                <br />
                Designed By <Link className="border-bottom" to="#">Robo Analytics</Link> Distributed By <Link className="border-bottom" to="#">Bhattacharjee Group</Link>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="">Home</Link>
                  <Link to="">Cookies</Link>
                  <Link to="">Help</Link>
                  <Link to="">FAQs</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="#" className="btn btn-lg btn-dark btn-lg-square back-to-top pt-2"><BiArrowToTop /></a> */}
    </>
  );
}
