import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Cloud_solution() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        "Cloud Platforms: IBM, Google, Azure",
        "Containerization: Kubernetes, Docker",
        "AWS Services: EC2, S3, Lambda",
        "Scalability & Security",
        " MLOps Tools: Dataiku",
        "Digital Transformation",
      
    ];

    const expertiessections = [
        {
            title: "Cloud Platforms",
            data: [
                "IBM",
                "Google",
                "Azure",
               
            ]
           
        },
        {
            title: "AWS Services",
            data: [
                "C2",
                "S3",
                "Lambda",
                
            ]
        },
       
        {
            title: "Containerization",
            data: [
                "Kubernetes", 
                "Docker", 
               
            ]
        },
        {
            title: "MLOps Tools",
            data: [
                "Dataiku", 
               
            ]
        }
    ];

    const sections = [
        {
            title: "Cloud ",
            subtitle:" Solutions",
            description: `
               At Robo Analytics, we excel in providing comprehensive cloud solutions tailored to meet the evolving needs of businesses across various industries. Leveraging top-tier cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS, including services like EC2, ECR, Route 53, Lambda, API Gateway, and S3, we ensure seamless scalability, reliability, and security for our clients' applications and data. Our expertise extends to containerization technologies like Kubernetes and Docker, enabling efficient deployment and management of cloud-native applications. With a focus on MLOps, we leverage tools like Dataiku to streamline machine learning workflows and optimize model deployment in the cloud environment. At Robo Analytics, we are committed to empowering businesses with robust and innovative cloud solutions that drive digital transformation and success.
            `,
            imgSrc: "img/cloud_solution.png"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                    {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                        {expertiessections.map((section, index) => (
                            <div key={index} className="benefit-card">
                                <div style={styles.title}>{section.title}</div>
                                <ul className="services_subheading">
                                    {section.data.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cloud_solution;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

  