import React from 'react';
import { Link } from 'react-router-dom';
// Import specific icons from react-icons
import { FaRobot, FaCloud, FaMobile, FaDesktop, FaLaptopCode, FaBullhorn } from 'react-icons/fa';
import './services.css'

const Services = () => {
  const services = [
    {
      title: "AI Research and Development",
      icon: <FaRobot />, // Use the FaRobot icon component
      description: "AI tool and software development involves creating intelligent systems and applications that can perform tasks traditionally requiring human intelligence.",
      link: "/ai",
      delay: "0.3s"
    },
    {
      title: "Cloud Solutions",
      icon: <FaCloud />, // Use the FaCloud icon component
      description: "We deliver cloud solutions across major cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS.",
      link: "/cloud-solutions",
      delay: "0.6s"
    },
    {
      title: "Mobile App Development",
      icon: <FaMobile />, // Use the FaMobile icon component
      description: "Mobile app development involves the creation of software applications specifically designed to run on mobile devices such as smartphones and tablets.",
      link: "/mobile-app",
      delay: "0.6s"
    },
    {
      title: "Web & Software Development",
      icon: <FaDesktop />, // Use the FaDesktop icon component
      description: "Our web development team stays up-to-date with the latest technological developments to offer the best solutions in terms of technology.",
      link: "/web-development",
      delay: "0.1s"
    },
    {
      title: "UX/UI Design",
      icon: <FaLaptopCode />, // Use the FaLaptopCode icon component
      description: "It has turned our UX/UI designers into experts in creating the most elegant and appealing UX web design.",
      link: "/ui-ux",
      delay: "0.1s"
    },
    {
      title: "Digital Marketing",
      icon: <FaBullhorn />, // Use the FaBullhorn icon component
      description: "Digital marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services.",
      link: "/digital-marketing",
      delay: "0.3s"
    }
  ];

  

  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container px-lg-5">
          <div className=" position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
            <div className="services_heading mt-2">What Solutions We <span className='highlight'>Provide</span> </div>
            <div className='services_subheading'>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</div>
            <span className="y-us-title-border"></span>
          </div>
          <div className="row g-4">
            {services.map((service, index) => (
              <div key={index} className={`col-lg-4 col-md-6 wow zoomIn`} data-wow-delay={service.delay}>
                <div className="service-item-it d-flex flex-column justify-content-center text-center rounded">
                  <div className="service-icon flex-shrink-0"style={{fontSize:'45px'}}>
                    {/* Use the icon component directly */}
                    {service.icon}
                  </div>
                  <div className="sevices_title mb-2">{service.title}</div>
                  <div className='services_text'>{service.description}</div>
                  <Link className="btn px-3 mt-auto mx-auto" to={service.link}>Read More</Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
