import React, { useState } from 'react'
import './solution.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

const Solution = () => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const testimonialSettings = {
        infinite: true,
        speed: 900,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        dots: true,
        arrows: true,
        fade: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    }

    const testimonials = [
        {
            id: 1,
            profession: "Real Estate",
            defaultImgSrc: "img/solution/cart-stores.cf9b5265.png",
            hoverImgSrc: "img/solution/building-hover.2471efa7.png"
        },
        {
            id: 2,
            profession: "FoodTech",
            defaultImgSrc: "img/solution/Department-stores.f22280fb.png",
            hoverImgSrc: "img/solution/deprt-hover.873619e7.png"
        },
        {
            id: 3,
            profession: "Edutech",
            defaultImgSrc: "img/solution/education.2253177c.png",
            hoverImgSrc: "img/solution/education-hover.d0dd48cd.png"
        },
        {
            id: 4,
            profession: "Healthcare",
            defaultImgSrc: "img/solution/Grocers.a69ccc5b.png",
            hoverImgSrc: "img/solution/med-hover.2afe652d.png"
        },
        {
            id: 5,
            profession: "Retail & E-commerce",
            defaultImgSrc: "img/solution/shpocart.3b32efe2.png",
            hoverImgSrc: "img/solution/shapocart-hover.8f16eaa1 (1).png"
        },
        {
            id: 6,
            profession: "Automotive",
            defaultImgSrc: "img/solution/Supermarkets.de730b32.png",
            hoverImgSrc: "img/solution/superm-hover.5d91868a.png"
        },
        {
            id: 7,
            profession: "Travel & Hospitality",
            defaultImgSrc: "img/solution/travel.c222aeaf.png",
            hoverImgSrc: "img/solution/tarvel-hover.2615f450.png"
        },

        {
            id: 8,
            profession: "FinTech ",
            defaultImgSrc: "img/solution/tech.767f5969.png",
            hoverImgSrc: "img/solution/tech-hover.a2301c8c.png"
        }
    ];
    return (
        <div>
            <div className=" solution_container container-xxl  py-5 my-5 wow fadeInUp" >
                <div style={{ textAlign: 'center', marginBottom: '10px', color: '#fff', display: 'flex', justifyContent: 'center' }}>
                    <div className='services_heading' style={{ color: '#fff', display: 'flex' }}>Solutions For Every <span className='highlight'style={{marginLeft:'10px'}}> Industry</span> </div>
                </div>
                <div className="container py-5 px-lg-5">
                    <Slider {...testimonialSettings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className={`testimonial-item bg-transparent rounded text-white p-4 ${hoveredIndex === index ? "slider-hover" : ""}`}>
                                <div
                                    style={{ display: "flex", flexDirection: "column", height: "22vh", alignItems: "center" }}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                >
                                    <img
                                        className="img-fluid flex-shrink-0"
                                        src={hoveredIndex === index ? testimonial.hoverImgSrc : testimonial.defaultImgSrc}
                                        style={{
                                            width: "104px",
                                            height: "114px",
                                            filter: hoveredIndex === index ? "none" : "brightness(0) invert(1)",
                                        }}
                                        alt={testimonial.profession}
                                    />
                                </div>
                                <div style={{ textAlign: "center", fontSize: "20px" }}>
                                    <small>{testimonial.profession}</small>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Solution
