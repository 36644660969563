import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Ai_Development() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        "Data Analysis and Visualization",
        "Machine Learning",
        "Deep Learning",
        "Gen AI",
        "Transfer Learning",
        "Transformer Architectures",
        "ML Tools",
        "AI Tools"
    ];

    const expertiessections = [
        {
            title: "Data Types",
            data: [
                "Numerical Data",
                "Time Series Data",
                "Structured Data",
                "Text Data",
                "Image Data (including Live Video analysis)"
            ]
        },
        {
            title: "Use Cases",
            data: [
                "Chat Bot",
                "GenAI",
                "Text classification",
                "Summarization",
                "Auto-Correction",
                "Language Translation",
                "Image Classification",
                "Object Detection"
            ]
        },
        {
            title: "Model Training",
            data: [
                "ElasticNet", 
                "Extra Tree Classifier", 
                "Random Forest",
                "LightGBM", 
                "AdaBoost", 
                "Xgboost", 
                "CatBoost",
                "Naive Bayes", 
                "SVC", 
                "KNN", 
                "K-Means Clustering",
                "CNN", 
                "RNN", 
                "GRU", 
                "LSTM", 
                "Bidirectional LSTM",
                "Bidirectional LSTM with custom Attention Layer"
            ]
        },
        {
            title: "Model Fine",
            data: [
                "VGGNet", 
                "ResNet", 
                "BERT", 
                "BART", 
                "t5-* (base, small, large)",
                "google/mt5-base", 
                "google/mt5-small", 
                "Byt5",
                "google/flan-t5-* (base, small, large, xl, xxl)",
                "facebook/mbart-large-50-many-to-many-mmt", 
                "mBART50", 
                "marianmt"
            ]
        }
    ];

    const sections = [
        {
            title: "AI Research and",
            subtitle:" Development",
            description: `
                At Robo Analytics, we harness an extensive array of state-of-the-art technologies to drive innovation across diverse domains.
                Our expertise spans advanced data analysis and visualization techniques, encompassing feature engineering and selection.
                In machine learning, we excel in regression, decision trees, random forests, AdaBoost, XGBoost, and various clustering algorithms.
                Deep learning is a forte, with proficiency in artificial neural networks, convolutional neural networks, recurrent neural networks, and transformer architectures like BERT and BART.
            `,
            imgSrc: "img/ai.jpg"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                    {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                        {expertiessections.map((section, index) => (
                            <div key={index} className="benefit-card">
                                <div style={styles.title}>{section.title}</div>
                                <ul className="services_subheading">
                                    {section.data.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ai_Development;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};
