import React, { useEffect, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Mousewheel from 'swiper/react'
import { Mousewheel } from 'swiper/modules';

import './our_experties.css';

const Our_experties = () => {
  const swiperRef = useRef(null); // Reference for Swiper instance
  const projects = [
    { id: 1, title: 'Project 1', imgSrc: 'img/web-project.jpg' },
    { id: 2, title: 'Project 2', imgSrc: 'img/app-project.jpg' },
    { id: 3, title: 'Project 3', imgSrc: 'img/extension.jpg' },
    { id: 4, title: 'Project 4', imgSrc: 'https://picsum.photos/600/400' },
    { id: 5, title: 'Project 5', imgSrc: 'https://picsum.photos/600/400' },
    { id: 6, title: 'Project 6', imgSrc: 'https://picsum.photos/600/400' },
  
  ];

  useEffect(() => {
    // Apply fade-in effect
    const thumb = document.querySelectorAll(".thumbContainer");
    thumb.forEach((image, index) => {
      const delay = index * 90;
      image.classList.add("fadeInSlide");
      image.style.animationDelay = `${delay}ms`;
    });
  }, []);

  return (
    <div className="swiper-container" ref={swiperRef}>
      <Swiper
        modules={[Mousewheel]} // Include Mousewheel module
        spaceBetween={30} // Space between slides set to 30px
        speed={1000} // Transition speed
        loop={false} // Disable looping
        slidesPerView="auto" // Set to 'auto'
        freeMode={true} // Enable free mode
        mousewheel={{
          releaseOnEdges: true, // Allow release on edges
        }}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <div className="thumbContainer">
         
              <img style={{height:"400px",width:"600px"}} src={project.imgSrc} alt={project.title} />
              <div className="projectInfo">
                <h2 className="serif">{project.title}</h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Our_experties;
