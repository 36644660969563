import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Ui_Ux() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        " Responsive Interfaces: HTML, CSS",
        " Prototyping: InVision, Zeplin",
        "User Testing",
        " Adobe XD, Sketch, Figma",
        "  Accessibility Compliance",
        " Iterative Refinement",
      
    ];
   
   

    const expertiessections = [
        {
            title: "Visual Design",
            data: [
                " Adobe XD",
                "Sketch",
                "Figma",
                
            ]
        
        },
        {
            title: "Responsive Interfaces",
            data: [
                "HTML",
                "CSS",
              
            ]
             
        },
   
      
        {
            title: "Prototyping",
            data: [
                "InVision",
                "Zeplin",
                
            ]
        },
      
 
        {
            title: "Our Approach",
            data: [
                "Responsive Designs",
                "User-friendly & User-interface Design Services",
                "Prototypes and Information Architecture",
                "Flexible Hiring Models",
                "Quality Processes",


            ]
        }
    ];
    

    const sections = [
        {
            title: "UX/UI ",
            subtitle:" Design",
            description: `
            At Robo Analytics, our UX/UI design services merge cutting-edge technologies with innovative design principles to create seamless and captivating user experiences. Leveraging advanced tools such as Adobe XD, Sketch, and Figma, our expert designers craft visually stunning interfaces tailored to user preferences and behaviors. We integrate responsive design techniques using HTML, CSS, and JavaScript frameworks like Bootstrap and Materialize to ensure optimal performance across various devices and screen sizes. Additionally, our proficiency extends to utilizing prototyping tools like InVision and Zeplin to facilitate iterative design processes and user testing. With a focus on accessibility and usability, we adhere to WCAG guidelines and employ techniques such as A/B testing and heatmaps to refine designs based on user feedback and analytics.
            `,
            imgSrc: "img/UX-UI-Design.png"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                    {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                        {expertiessections.map((section, index) => (
                            <div key={index} className="benefit-card">
                                <div style={styles.title}>{section.title}</div>
                                <ul className="services_subheading">
                                    {section.data.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ui_Ux;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

  