
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { FaRobot, FaCloud, FaMobile, FaDesktop, FaLaptopCode, FaBullhorn } from 'react-icons/fa';
import './our_services.css'

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Our_service() {
    const [selected, setSelected] = useState(0);

    const services = [
        {
            title: "AI Research and Development",
            icon: <FaRobot />, // Use the FaRobot icon component
            description: "AI tool and software development involves creating intelligent systems and applications that can perform tasks traditionally requiring human intelligence.",
            link: "/ai",
            delay: "0.3s"
        },
        {
            title: "Cloud Solutions",
            icon: <FaCloud />, // Use the FaCloud icon component
            description: "We deliver cloud solutions across major cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS.",
            link: "/cloud-solutions",
            delay: "0.6s"
        },
        {
            title: "Mobile App Development",
            icon: <FaMobile />, // Use the FaMobile icon component
            description: "Mobile app development involves the creation of software applications specifically designed to run on mobile devices such as smartphones and tablets.",
            link: "/mobile-app",
            delay: "0.6s"
        },
        {
            title: "Web & Software Development",
            icon: <FaDesktop />, // Use the FaDesktop icon component
            description: "Our web development team stays up-to-date with the latest technological developments to offer the best solutions in terms of technology.",
            link: "/web-development",
            delay: "0.1s"
        },
        {
            title: "UX/UI Design",
            icon: <FaLaptopCode />, // Use the FaLaptopCode icon component
            description: "It has turned our UX/UI designers into experts in creating the most elegant and appealing UX web design.",
            link: "/ui-ux",
            delay: "0.1s"
        },
        {
            title: "Digital Marketing",
            icon: <FaBullhorn />, // Use the FaBullhorn icon component
            description: "Digital marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services.",
            link: "/digital-marketing",
            delay: "0.3s"
        }
    ];
    const faqs = [
        { question: 'Do you provide end-to end software delivery?', answer: 'Right from designing to development, implementation to integration, setup and post-deployment support – Robo Analytics is your one-stop-shop for end-to-end delivery. We set up ‘right’ systems for you, ensure minimum cost, incorporate best materials and deliver industry-grade infrastructure. With our end-to-end solutions, we reduce development hassle, cost, resources and time so that your business is able to keep pace with the ever-changing market needs.' },
        { question: 'How much does custom software cost?', answer: 'There’s no straight answer to this honestly! The cost of a custom software depends on several factors – the kind of framework and technology used, the competency of engineers and seniority of resources required and most importantly, the tailored capabilities that the project requires to have an edge of competitors. However, we do ensure that our pricing is the best in the market – just like our quality!' },
        { question: 'Will you match my timeline?', answer: 'Our development process is a very uncomplicated one. After the initial requirement gathering process, we develop a blueprint clearly defining your requirements and finalizing a mutually agreed upon timeline. We have a superb track record of adhering to timelines and completing projects on time with minimal bottlenecks.' },
        { question: 'How do we assure privacy and confidentiality?', answer: 'Right from the time you discuss your digital vision with us, we sign an NDA document with you – which means that your concepts are safe with us. Also, Radixweb’s development process adheres to industry standards, which means there is no scope of data breach from our end.' },
        { question: 'Software Development Company India - Why Choose us?', answer: 'Software Development Company India, like Robo Analytics will help you build high-quality and innovative software solutions that meet the unique requirements of your business. We have highly skilled software engineers with excellent technical knowledge in using the latest software standards, tools, platforms, frameworks, and technologies that meet the industry’s most stringent requirements for development.' },
        { question: 'Which Software Development Methodology you follow?', answer: 'Being a software development firm that works with thousands of global businesses integrating modern technology, our primary preference is working with the Agile Methodology – because it helps integrating wonderous possibilities of new technologies. However, our engineers are proficient in waterfall technologies too and can develop your software on any methodology as requested.' },
        { question: 'Do you ensure quality software delivery?', answer: 'Robo Analytics is globally known for quality software that has triggered digital transformation for many businesses and helped them reach new heights. We constantly integrate QA and testing after each phase of development, so that all discrepancies are detected well in time and remedied before moving on to the next phase of development. Quality is something our clients never have to worry with Robo Analytics!' }

    ];
    const radius = 150; // Radius of the circle

    const technologies = [
        { src: "/img/services_icon/png/reactjs.png", alt: "React js" },
        { src: "/img/services_icon/png/Android_Studio.png", alt: "Android_Studio" },
        { src: "/img/services_icon/png/f.png", alt: "flask" },
        { src: "/img/services_icon/png/python.png", alt: "python" },
        { src: "/img/services_icon/png/sprin.png", alt: "sprin" },
        { src: "/img/services_icon/png/js.png", alt: "js" },
        { src: "/img/services_icon/png/figma.png", alt: "figma" },
        { src: "/img/services_icon/png/php.png", alt: "PHP" },
        { src: "/img/services_icon/png/nodejs.png", alt: "NodeJS" },
        { src: "/img/services_icon/png/Google-Cloud.png", alt: "Google-Cloud" },
        { src: "/img/services_icon/png/css.png", alt: "css" },
        { src: "/img/services_icon/png/ps.png", alt: "ps" },
        { src: "/img/services_icon/png/xd.png", alt: "xd" },
        { src: "/img/services_icon/png/aws.png", alt: "aws" },
        { src: "/img/services_icon/png/java.png", alt: "java" },
        { src: "/img/services_icon/png/laravel.png", alt: "laravel" },
        { src: "/img/services_icon/png/t.png", alt: "t" },
        { src: "/img/services_icon/png/port.png", alt: "port" },



    ];



    const toggle = (i) => {
        // Toggle the clicked FAQ, including the first one
        setSelected(selected === i ? null : i);
    };

    useEffect(() => {
        scrollToTop();
    }, []);
    return (
        <div>
           
            <div className="  hero-header ">
                <div className="position-relative " style={{ height: "500px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 " style={{ paddingTop: '6rem' }}>
                           
                            <div className="col-lg-6" style={{ display:'flex',flexDirection:"column",justifyContent:'center', alignItems: 'center' }}>

                                <div className="services_heading text-white mt-1  mb-3 animated slideInRight">Services We Provide</div>
                                <div className=" services_heading highlight    mb-5 animated slideInRight">Our Capabilities</div>

                            </div>

                            <div className="col-lg-6" style={{ position: 'relative', height: '352px', bottom: "6vh" }}>
                                {technologies.map((tech, index) => {
                                    // Define specific positions along with width and height for each image
                                    const positions = [
                                        { top: '10%', left: '5%', width: '60px', height: '60px' },
                                        { top: '44%', left: '34%', width: '70px', height: '70px' },
                                        { top: '15%', left: '56%', width: '80px', height: '80px' },
                                        { top: '52%', left: '2%', width: '50px', height: '50px' },
                                        { top: '50%', left: '80%', width: '75px', height: '75px' },
                                        { top: '10%', left: '40%', width: '60px', height: '60px' },
                                        { top: '86%', left: '8%', width: '55px', height: '50px' },
                                        { top: '80%', left: '70%', width: '55px', height: '55px' },
                                        { top: '0%', left: '18%', width: '85px', height: '85px' },
                                        { top: '62%', left: '15%', width: '80px', height: '80px' },
                                        { top: '35%', left: '22%', width: '45px', height: '45px' },
                                        { top: '40%', left: '56%', width: '38px', height: '70px' },
                                        { top: '26%', left: '90%', width: '38px', height: '60px' },
                                        { top: '78%', left: '45%', width: '70px', height: '70px' },
                                        { top: '56%', left: '50%', width: '80px', height: '80px' },
                                        { top: '74%', left: '94%', width: '50px', height: '50px' },
                                        { top: '0%', left: '75%', width: '137px', height: '90px' },
                                        { top: '76%', left: '28%', width: '60px', height: '60px' },

                                    ];

                                    // Get the current position for the image based on its index
                                    const { top, left, width, height } = positions[index % positions.length]; // Loop through defined positions

                                    return (
                                        <img
                                            key={index}
                                            src={tech.src}
                                            alt={tech.alt}
                                            style={{
                                                position: 'absolute',
                                                top: top,
                                                left: left,
                                                width: width, // Use the width from positions
                                                height: height, // Use the height from positions
                                                objectFit: 'contain', // Maintain aspect ratio
                                                transition: 'transform 0.3s',
                                                borderRadius: '10%', // Optional: rounded corners
                                            }}
                                            onMouseEnter={e => {
                                                e.currentTarget.style.transform = 'scale(1.1)'; // Scale image on hover
                                            }}
                                            onMouseLeave={e => {
                                                e.currentTarget.style.transform = 'scale(1)'; // Reset scale on mouse leave
                                            }}
                                        />
                                    );
                                })}
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className=" position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="services_heading mt-2">What Solutions We <span className='highlight'>Provide</span> </div>
                        <div className='services_subheading'>We deliver innovative, reliable software solutions tailored to your business. With expert teams, transparent pricing, and ongoing support.</div>
                        <span className="y-us-title-border"></span>
                    </div>
                    <div className="row g-4">
                        {services.map((service, index) => (
                            <div key={index} className={`col-lg-4 col-md-6 wow zoomIn`} data-wow-delay={service.delay}>
                                <div className="service-item-it d-flex flex-column justify-content-center text-center rounded">
                                    <div className="service-icon flex-shrink-0" style={{ fontSize: '45px' }}>
                                        {/* Use the icon component directly */}
                                        {service.icon}
                                    </div>
                                    <div className="sevices_title mb-3">{service.title}</div>
                                    <div className='services_text'>{service.description}</div>
                                    <Link className="btn px-3 mt-auto mx-auto" to={service.link}>Read More</Link>
                                </div>
                            </div>
                        ))}
                    </div>

                    < div className="client-section" >
                        <div className='client_section_text'>
                            More than <span className="highlight">100+ Happy</span> <br />  <span className="highlight">Clients</span> over <span className="highlight">10+ Years</span>  of work
                        </div>
                        <button className="cta-button">BECOME OUR CLIENT</button>
                    </div >

                    <div className=" hero-header row project-section" >
                        <div className="project-content col-6">
                            <div className='project_content_text'>
                                We Deliver <span className="project_highlight">125 Web & Mobile<br /> Projects</span> per Year
                            </div>
                            <button className="cta-button">Start Project Now</button>
                        </div>
                        <div className="project-image col-6">
                            <img src="/img/img-we-deliver.png" alt="Laptop" />
                        </div>
                    </div>

                    {/* Faq Section */}
                    <div className="faq-container mt-5" style={{ width: '100%' }}>
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                <div className='services_heading'>Frequently  <span className='highlight'>Asked</span>  Questions</div>
                                <div className='services_subheading'>Frequently asked question (FAQ) pages to find answers.</div>
                                {/* <img src="img/faq.jpg" style={{ width: "40vw", marginLeft: "-65px" }} /> */}
                            </div>

                            <div className="col-lg-6 fadeInUp" data-wow-delay="0.2s">
                                <div className="faq">
                                    {faqs.map((item, i) => (
                                        <div className="faq-item" key={i}>
                                            <div className="faq-question" onClick={() => toggle(i)}>
                                                <div className='subheading mb-2'>{item.question}</div>
                                                <span style={{ fontSize: "24px" }}>{selected === i ? '-' : '+'}</span>
                                            </div>
                                            <div className={selected === i ? 'faq-answer show ' : 'faq-answer'}>
                                                <div className='services_subheading'>{item.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


          
        </div>
    )
}

export default Our_service
