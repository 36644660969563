import React from 'react'
import { Link } from 'react-router-dom'
import './project_help.css'

const Project_help = () => {
    return (
        <div>
            <div className=" row project-section mt-5">
                <div className="project-content col-6">
                    <div className='project_content_text'>
                        Want to accelerate software development at your company?<br />  <span className="highlight">See how we can help</span> per Year
                    </div>
                    <Link to="/contact">
                        <button className="cta-button">Schedule a Call</button>
                    </Link>
                </div>
                <div className="project-image col-6">
                    <img style={{ maxWidth: "100%" }} src="/img/project-help.avif" alt="project" />
                </div>
            </div>
        </div>
    )
}

export default Project_help
