import React from 'react';
import './portfolio.css';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <div className="portfolio-section " style={{ marginTop: '4%' }}>

            <div className="portfolio-header mb-5 ">
                <div className=' subheading'>Explore Our Portfolios</div>
                <div className='services_heading highlight'>Experience the Difference</div>
                <div className='porfolio-subtext services_subheading'>
                    The success of any project hinges on multiple factors, but aligning with our clients' goals is our top priority.
                    When we collaborate closely, success naturally follows! Discover how we turn visions into reality.
                </div>
            </div>

            <div className="row g-5 mb-5  align-items-center">
                <div className="col-lg-7  wow zoomIn" data-wow-delay="0.5s">

                    <div className="image-section1">
                        <img className="portfolio-image1" src="img/linkomatic.png" />
                    </div>
                </div>

                <div className="col-lg-5 fadeInUp portfolio-contant-box" data-wow-delay="0.2s">
                    <div className="portfolio-logo-section">
                        <img src="img/linko_logo.png" alt="TRISPIRE Logo" className="portfolio-logo" style={{border:'2px solid grey'}}/>
                        <div className='portfolio-logo-text'>   
                             <div className="portfolio-title">Robo Analytics</div>
                            <div className="services_subheading">
                                <span className='highlight'>TECHNOLOGY:</span> PYTHON | REACT JS | AI
                            </div>
                            </div>

                    </div>
                    {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                    <div className="subheading mb-4">Linkomatic  </div>
                    <div className="services_subheading mb-4">   Trispire Solutions, one of our latest projects, is about transforming healthcare delivery through innovative technologies. The mission of this website is to empower healthcare providers by offering them a comprehensive platform designed to streamline operations, improve patient engagement, and optimize care management. With its solutions, healthcare professionals can concentrate on what truly matters, that is delivering exceptional patient care while navigating the complexities of the modern healthcare landscape.</div>
                    <Link to="#">
                        <button className="portfolio-button">Read Details</button>
                    </Link>
                </div>
            </div>

            <div className="row g-5 mb-5  align-items-center">

                <div className="col-lg-5 fadeInUp portfolio-contant-box" data-wow-delay="0.2s">
                <div className="portfolio-logo-section">
                        <img src="img/sooper.png" alt="TRISPIRE Logo" className="portfolio-logo" style={{border:'2px solid black'}} />
                        <div className='portfolio-logo-text'>   
                             <div className="portfolio-title">Sooper ai</div>
                            <div className="services_subheading">
                                <span className='highlight'>TECHNOLOGY:</span> NodeJS | NEXT JS | REACT JS
                            </div>
                            </div>

                    </div>
                    {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                    <div className="subheading mb-4">Sooper Ai website </div>
                    <div className="services_subheading mb-4">RoboCent is dedicated to empowering political campaigns with cutting-edge communication tools that enhance voter engagement and drive impactful outreach. This platform provides a comprehensive suite of features designed to streamline the process of connecting with constituents, ensuring that a campaign runs smoothly and effectively at every stage. By leveraging innovative technology and strategic insights, RoboCent helps campaigns navigate the complex political landscape with confidence.</div>
                    <Link to="#">
                        <button className="portfolio-button">Read Details</button>
                    </Link>
                </div>

                <div className="col-lg-7  wow zoomIn" data-wow-delay="0.5s">

                    <div className="image-section2">
                        <img className="portfolio-image2" src="img/sooper_website.png" />
                    </div>
                </div>
            </div>

            <div className="row g-5 mb-5  align-items-center">
                <div className="col-lg-7  wow zoomIn" data-wow-delay="0.5s">

                    <div className="image-section1">
                        <img className="portfolio-image1" src="img/apty.png" />
                    </div>
                </div>
                <div className="col-lg-5 fadeInUp portfolio-contant-box" data-wow-delay="0.2s">
                <div className="portfolio-logo-section">
                        <img src="img/apty_logo.png" alt="TRISPIRE Logo" className="portfolio-logo" style={{border:'2px solid #ff0000'}} />
                        <div className='portfolio-logo-text'>   
                             <div className="portfolio-title">Apty speak</div>
                            <div className="services_subheading">
                                <span className='highlight'>TECHNOLOGY:</span> PYTHON | NODE JS | AI | REACT NATIVE
                            </div>
                            </div>

                    </div>
                    {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                    <div className="subheading mb-4">Apty Speak App</div>
                    <div className="services_subheading mb-4">Apty speak is the one stop solution for any lighting project. When they came up with the idea to stronger their online presence then we helped them with the most innovative and effective ideas. As a company with global presence, they need the most appropriate solution to cater their audience. It needs to be sleek, content driven, compact, outreaching and customer engaging and we met up to their expectation by smartly understanding and implementing what they wanted.</div>
                    <Link to="#">
                        <button className="portfolio-button">Read Details</button>
                    </Link>
                </div>
            </div>

            <div className="row g-5 mb-5  align-items-center">

                <div className="col-lg-5 fadeInUp portfolio-contant-box" data-wow-delay="0.2s">
                <div className="portfolio-logo-section">
                        <img src="img/sooper.png" alt="TRISPIRE Logo" className="portfolio-logo" style={{border:"2px solid black"}}/>
                        <div className='portfolio-logo-text'>   
                             <div className="portfolio-title">Sooper ai</div>
                            <div className="services_subheading">
                                <span className='highlight'>TECHNOLOGY:</span> PYTHON | NODE JS | REACT JS
                            </div>
                            </div>

                    </div>
                    {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                    <div className="subheading mb-4">Browser Extension</div>
                    <div className="services_subheading mb-4">OUI is dedicated to empowering political campaigns with cutting-edge communication tools that enhance voter engagement and drive impactful outreach. This platform provides a comprehensive suite of features designed to streamline the process of connecting with constituents, ensuring that a campaign runs smoothly and effectively at every stage. By leveraging innovative technology and strategic insights, RoboCent helps campaigns navigate the complex political landscape with confidence.</div>
                    <Link to="#">
                        <button className="portfolio-button">Read Details</button>
                    </Link>
                </div>

                <div className="col-lg-7  wow zoomIn" data-wow-delay="0.5s">

                    <div className="image-section2">
                        <img className="portfolio-image2" src="img/extension.png" />
                    </div>
                </div>
            </div>

            {/* <div className="row g-5 mb-5  align-items-center">
                <div className="col-lg-7  wow zoomIn" data-wow-delay="0.5s">

                    <div className="image-section1">
                        <img className="portfolio-image1" src="img/1677752771_img.png" />
                    </div>
                </div>
                <div className="col-lg-5 fadeInUp portfolio-contant-box" data-wow-delay="0.2s">
                <div className="portfolio-logo-section">
                        <img src="img/1677752771_logo.png" alt="TRISPIRE Logo" className="portfolio-logo" />
                        <div className='portfolio-logo-text'>   
                             <div className="portfolio-title">DJ RAPHAEL NUEVO</div>
                            <div className="services_subheading">
                                <span className='highlight'>TECHNOLOGY:</span> MYSQL | VUE JS | WORDPRESS
                            </div>
                            </div>

                    </div>
                    <div className="subheading mb-4">Promotional and marketing website of DJ Raphael Nuevo for booking on weddings or for other events. </div>
                    <div className="services_subheading mb-4">This website was pretty much required for the rebranding of DJ RAPHAEL NUEVO where more people can get in touch with him and also get a taste of his music. We gave him the perfect solution to get maximum return on his investment.</div>
                    <Link to="#">
                        <button className="portfolio-button">Read Details</button>
                    </Link>
                </div>
            </div> */}
        </div>
    );
};

export default Portfolio;
