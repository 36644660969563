import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Web_development() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        "Microservice Architecture: Python, Node.js",
        " Real-time Communication: Socket.IO",
        "Web Interface: Angular, React",
        " Comprehensive Testing: Mocha, Chai, PM2",
        "  Database Management: SQL, MongoDB",
        "Optimization: Webpack, Carbon Design",
      
    ];
   
    



   
   
    

    const expertiessections = [
        {
            title: "Microservice Technologies",
            data: [
                "Python",
                "Node js",
                "Express js",
                "Cors",
                "Socket.IO",

            ]
           
        },
        {
            title: "Comprehensive Testing",
            data: [
                "Mocha",
                "Chai",
                "PM2",
               
            ]
        }, 
        
   
      
        {
            title: "Database Management",
            data: [
                "SQL",
                "MongoDB",
                
            ]
            
        },
        {
            title: "Web Technologies",
            data: [
                "Angular",
                "React",
                "Webpack",
               " Carbon Design System"
            ]
        }
      
    ];
    

    const sections = [
        {
            title: "Web & Software ",
            subtitle:" Development",
            description: `
             Development Our web development team makes sure that they stay up-to-date with the latest technological developments in this area so as to offer the best solutions in terms of technology. When you bring your business problem to us, we provide the best technology solutions suitable for your business. In combination with outstanding graphics, we help your business to make a positive impact on your target audience.
            `,
            imgSrc: "img/web_dev.png"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                    {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                        {expertiessections.map((section, index) => (
                            <div key={index} className="benefit-card">
                                <div style={styles.title}>{section.title}</div>
                                <ul className="services_subheading">
                                    {section.data.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Web_development;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

  