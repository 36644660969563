import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Digital_marketing() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        " Search Engine Optimization",
        "Analytics",
        "Pay Per Click",
        "  Social Media Marketing",
        "   Email Marketing",
        "  Content Marketing",

    ];


    const expertiessections = [
        {
            title: "SEO (Search Engine Optimization)",
            data: [
                "On-page SEO",
                "Off-page SEO",
                "Technical SEO",
                "Keyword Research",
            ]
        },
        {
            title: "Content Marketing",
            data: [
                "Blog Writing",
                "Video Content",
                "Infographics",
                "E-books",
            ]
        },
        {
            title: "Social Media Marketing",
            data: [
                "Facebook Marketing",
                "Instagram Advertising",
                "LinkedIn Strategies",
                "Twitter Engagement",
            ]
        },
        {
            title: "Email Marketing",
            data: [
                "Newsletter Campaigns",
                "Email Automation",
                "List Segmentation",
                "A/B Testing",
            ]
        }
    ];
    


    const sections = [
        {
            title: "Digital  ",
            subtitle: "Marketing",
            description: `
           Digital Marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services. Online marketing has gained a lot of popularity and hence some products don’t even consider advertising through traditional media. Our digital marketing team has successfully completed a large number of projects and has expertise in Google Analytics, Social Media Marketing, and SEO. Connect with us to know more .
            `,
            imgSrc: "img/digital-marketing.webp"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                        {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                            {expertiessections.map((section, index) => (
                                <div key={index} className="benefit-card">
                                    <div style={styles.title}>{section.title}</div>
                                    <ul className="services_subheading">
                                        {section.data.map((item, idx) => (
                                            <li key={idx}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Digital_marketing;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

