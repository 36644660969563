import React from 'react'
import './ceo.css'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'

const Ceo = () => {
  return (
    <div>
         <div className="container-fluid ">
                <div className="container ">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
                            <div className="services_heading mb-4">Meet Our Founder & <span className='highlight'>CEO</span></div>
                            <div className="services_subheading mb-4">Meet our Founder & CEO, who brings over 13 years of invaluable expertise to our organization. With a profound understanding of the industry cultivated over more than a decade, our leader has navigated the complexities of this field with unparalleled vision and determination. Their strategic insights and leadership have propelled our company to new heights, shaping our journey towards continued success.</div>
                        </div>
                        <div className="col-lg-5">
                           
                                            <div className="team-item text-center rounded p-4 pt-0" style={{ backgroundColor: "#faf5f5" }}>
                                                <img className="img-fluid rounded-circle p-4" src="img/team-1.png" alt="Abhishek Bhattacharjee" />
                                                <div className="mb-0">Abhishek Bhattacharjee</div>
                                                <div>Founder & CEO</div>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <Link className="btn btn-square btn-dark m-1" to=""><FaFacebookF /></Link>
                                                    <Link className="btn btn-square btn-dark m-1" to=""><FaTwitter /></Link>
                                                    <Link className="btn btn-square btn-dark m-1" to=""><FaInstagram /></Link>
                                                    <Link className="btn btn-square btn-dark m-1" to=""><FaLinkedinIn /></Link>
                                             
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Ceo
