import { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import './contact.css';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMdMail } from "react-icons/io";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function Contact() {
    
    useEffect(() => {
        scrollToTop();
    }, []);

    const [value, setValue] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { name, email, subject, message } = value;

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        // Validate form fields
        if (!name || !email || !subject || !message) {
            alert("Please fill in all fields before sending your message.");
            return; // Exit the function if any field is empty
        }

        // Define the parameters for the email template
        const templateParams = {
            name,
            email,
            subject,
            message,
        };

        // Send email through EmailJS
        emailjs.send(
            "service_h04hq1i",      // Replace with your EmailJS Service ID
            "template_zkukip8",      // Replace with your EmailJS Template ID
            templateParams,
            "uEbYnxbak5pVxroo-"      // Replace with your EmailJS User ID
        )
        .then((response) => {
            console.log("Email sent successfully!", response.status, response.text);
            setValue({ name: "", email: "", subject: "", message: "" });  // Clear the form
            setIsModalOpen(true);  // Open the success modal
        })
        .catch((error) => {
            console.error("Failed to send email:", error);
        });
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container-xxl py-5" style={{ marginTop: '4%' }}>
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="services_heading mb-4">
                        Contact <span className='highlight'>For Any Query</span>
                    </div>
                </div>

                <div className="row g-4">
                    <div className="col-12 mb-2">
                        <div className="row gy-4">
                            <div className="col-md-4">
                                <div className="contact_title mb-1 text-start text-uppercase">Email Id</div>
                                <div className="services_subheading">
                                    <IoMdMail className="me-2" /> hr.roboanalytics@gmail.com
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="contact_title mb-1 text-start text-uppercase">Contact No</div>
                                <div className="services_subheading">
                                    <FaPhoneAlt className="me-2" /> +91 6290 382 261
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="contact_title mb-1 text-start text-uppercase">Official Email Id</div>
                                <div className="services_subheading">
                                    <IoMdMail className="me-2" /> bhattacharjeegroup@gmail.com
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                        <iframe
                            className="position-relative rounded w-100 h-100"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.389228988559!2d88.4429931!3d22.612841200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f041e77c35b%3A0xcfa945e63f08fc75!2sRobo%20Analytics!5e0!3m2!1sen!2sin!4v1705593932787!5m2!1sen!2sin"
                            tabIndex="0"
                        ></iframe>
                    </div>
                    <div className="col-md-6">
                        <form onSubmit={onSubmit}>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Your Name"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Your Email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            placeholder="Subject"
                                            name="subject"
                                            value={subject}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea
                                            className="form-control"
                                            placeholder="Leave a message here"
                                            id="message"
                                            name="message"
                                            value={message}
                                            onChange={handleChange}
                                            style={{ height: "150px" }}
                                        ></textarea>
                                        <label htmlFor="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="cta-button w-100" type="submit">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            {isModalOpen && (
                <div className="modal-backdrop">
                    <div className="modal-content">
                        <p>Thank you for connecting! <br />Your message has been sent successfully.</p>
                        <button onClick={closeModal} className="cta-button">Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}
