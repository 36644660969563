import React from 'react'
import './why_choose.css';

const Why_choose_us = () => {

    
    const servicesLeft = [
        {
            title: 'Experience',
            description: 'Leverage years of industry experience to deliver high-quality software solutions that meet your business needs.',
            icon: 'img/choose_icon/icon-1.png',
        },
        {
            title: 'Products',
            description: 'Innovative software products designed to enhance productivity and streamline operations for businesses of all sizes.',
            icon: 'img/choose_icon/icon-2.png',
        },
        {
            title: 'Approach',
            description: 'A client-centric approach that emphasizes collaboration and agile methodologies for effective project delivery.',
            icon: 'img/choose_icon/icon-3.png',
        },
    ];

    const servicesRight = [
        {
            title: 'Pricing',
            description: 'Transparent and competitive pricing models tailored to provide maximum value for your investment in software solutions.',
            icon: 'img/choose_icon/icon-4.png',
        },
        {
            title: 'Delivery',
            description: 'Timely delivery of software solutions with a focus on quality assurance and rigorous testing protocols.',
            icon: 'img/choose_icon/icon-5.png',
        },
        {
            title: 'Support',
            description: 'Comprehensive support services including maintenance, troubleshooting, and continuous improvements to ensure optimal performance.',
            icon: 'img/choose_icon/icon-6.svg',
        },
    ];

  return (
    <div>
        <div className="y-us-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-md-offset-2 col-sm-12 col-xs-12">
                            <div className="y-us-head">

                                <div className="s position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                                    {/* <h6 className="position-relative d-inline text-primary ps-4">Our Projects</h6> */}
                                    <div className="services_heading mt-2">Why choose <span className='highlight'>us</span></div>
                                    <div className='services_subheading'>Choose us for innovative, reliable software solutions tailored to your business needs. We offer expert teams, transparent pricing, and dedicated ongoing support.</div>
                                    <span className="y-us-title-border"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Left Services */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="y-us-content">
                                <div className="why_us">
                                    {servicesLeft.map((service, index) => (
                                        <div className="why_us-box" key={index}>
                                            <div className="clearfix">
                                                <div className="iconset_right">
                                                    <span className=" icon">
                                                        <img src={service.icon} />
                                                    </span>
                                                </div>
                                                <div className="y-us-content_left">
                                                    <div className='subheading'>{service.title}</div>
                                                    <div className='services_subheading'>{service.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Image Section */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="why_choose_banner">
                                <img src="img/features.jpg" alt="Why Choose Us" />
                            </div>
                        </div>

                        {/* Right Services */}
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="y-us-contentbox">
                                <div className="why_us">
                                    {servicesRight.map((service, index) => (
                                        <div className="why_us-box" key={index}>
                                            <div className="clearfix">
                                                <div className="iconset_left">
                                                    <span className="glyphicon glyphicon-cog icon">
                                                        <img src={service.icon} alt={service.title} />
                                                    </span>
                                                </div>
                                                <div className="y-us-content_right">
                                                    <div className='subheading'>{service.title}</div>
                                                    <div className='services_subheading'>{service.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Why_choose_us
