import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './page/Home';
import Our_product from './compnent/our_product/Our_product';
import Our_product2 from './compnent/our_product2/Our_product2';
import Contact from './page/contact/Contact';
import Career from './page/career/Career';
import Our_service from './page/our_services/Our_service';
import Header from './common/Header';
import Footer from './common/footer/Footer';
import Ai_Development from './page/Our_Services_pages/Ai_Development';
import Our_experties from './compnent/our_experties/Our_experties';
import Cloud_solution from './page/Our_Services_pages/Cloud_solution';
import Mobile_app_development from './page/Our_Services_pages/Mobile_app_development';
import Web_development from './page/Our_Services_pages/Web_development';
import Ui_Ux from './page/Our_Services_pages/Ui-Ux';
import Digital_marketing from './page/Our_Services_pages/Digital_marketing';
import Launch_product_video from './compnent/launch_product_videos/Launch_product_video';
import Portfolio from './page/portfolio/Portfolio';
import Modal from './compnent/modal/Modal';




function App() {
  return (
    <Router>
      
      <Header/>
      <Routes>
        {/* nav bar */}
        <Route path='/' element={<Home />} />
        <Route path='/career' element={<Career/>} />
        <Route path='/our-services' element={<Our_service/>} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/portfolio' element={<Portfolio />} />


        {/* pages */}

        <Route path='/ai' element={<Ai_Development/>} />
        <Route path='/cloud-solutions' element={<Cloud_solution/>} />
        <Route path='/mobile-app' element={<Mobile_app_development/>} />
        <Route path='/web-development' element={<Web_development/>} />
        <Route path='/ui-ux' element={<Ui_Ux/>} />
        <Route path='/digital-marketing' element={<Digital_marketing/>} />






        <Route path='/our-product' element={<Our_product/>} />
        <Route path='/our-video' element={<Launch_product_video/>} />

        <Route path='/our-experties' element={<Our_experties/>} />

        <Route path='/our-product2' element={<Our_product2/>} />
        <Route path='/modal' element={<Modal/>} />

      </Routes>

      <Footer />
    
    </Router>
  );
}

export default App;
