import React from 'react'
import './banner.css';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
       <div className="hero-section overlay-bottom">
                    <div className="content">
                        <div className='banner_title'>Digital, Business, & Technology Partners for Success</div>
                        <div className="highlight banner_title">Robo Analytics.</div>
                        <div className='banner_text mb-3'>
                        We simplify the digital transformation journeys of businesses through smart and innovative software solutions.
                        </div>
                        <Link to="/contact">
                        <button className="cta-button">Schedule a Call</button>
                    </Link>
                    </div>
                </div>
    </>
  )
}

export default Banner
