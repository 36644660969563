import React, { useRef, useLayoutEffect, useState } from "react";
import { TimelineMax, Power4, Power3, Power2 } from "gsap";
import { AiOutlineClose } from "react-icons/ai";
import "./Modal.css";
import emailjs from "emailjs-com";

const Modal = ({ 
  isOpen, 
  onClose, 
  formFields = [], 
   
}) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false); // Loading state for submission
  const modalRef = useRef(null);
  const contentRef = useRef(null);
  const polygonRef = useRef(null);
  const svgRef = useRef(null);
  const animationRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const closeModal = () => {
    if (animationRef.current) {
      animationRef.current.reverse();
    }
  };

  useLayoutEffect(() => {
    if (isOpen) {
      setFormData(
        formFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
      );

      const polygon = polygonRef.current;
      const svg = svgRef.current;

      if (svg && polygon) {
        const points = [
          createPoint(45, 45),
          createPoint(55, 45),
          createPoint(55, 55),
          createPoint(45, 55),
        ];

        animationRef.current = new TimelineMax({
          paused: true,
          onStart: () => {
            if (modalRef.current) {
              modalRef.current.style.display = "flex";
            }
          },
          onReverseComplete: () => {
            if (modalRef.current) {
              modalRef.current.style.display = "none";
              onClose(); // Call onClose only after the reverse animation completes
            }
          },
        })
          .to(points[0], 0.3, { x: 15, y: 30, ease: Power4.easeIn }, 0)
          .to(points[3], 0.3, { x: 5, y: 80, ease: Power2.easeIn }, "-=0.1")
          .to(points[0], 0.3, { x: 0, y: 0, ease: Power3.easeIn })
          .to(points[1], 0.3, { x: 100, y: 0, ease: Power2.easeIn }, "-=0.2")
          .to(points[2], 0.3, { x: 100, y: 100, ease: Power2.easeIn })
          .to(points[3], 0.3, { x: 0, y: 100, ease: Power2.easeIn }, "-=0.1")
          .to(modalRef.current, 1, { autoAlpha: 1 }, 0)
          .to(contentRef.current, 1, { autoAlpha: 1 });

        function createPoint(x, y) {
          const point = polygon.points.appendItem(svg.createSVGPoint());
          point.x = x || 0;
          point.y = y || 0;
          return point;
        }

        animationRef.current.play();
      }
    } else {
      setFormData({});
    }
  }, [isOpen, formFields]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true); // Set loading to true on form submit

  //   emailjs.send("service_pfff2p3", "template_c4umbyp", formData, "uEbYnxbak5pVxroo-")
  //     .then((response) => {
  //       console.log("Email sent successfully!", response.status, response.text);
  //       setLoading(false); // Reset loading
  //       closeModal(); // Trigger the closing animation after successful email sending
  //     })
  //     .catch((error) => {
  //       console.error("Failed to send email:", error);
  //       setLoading(false); // Reset loading on error
  //     });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Debugging to verify formData and email
    console.log("Form Data:", formData);
    console.log("Recipient Email:", formData.email);

    // Check if email exists in formData before proceeding
    if (!formData.email) {
        console.error("No email provided in formData. Cannot send emails.");
        setLoading(false);
        return;
    }

    // First email submission
    emailjs.send("service_wv069en", "template_g0w1d95", formData, "j9glKhln_Oj2BLnYu")
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);

        // Auto-reply email data with verified email
        const autoReplyData = {
          to_email: formData.email, // Ensure email is defined in formData
        };

        // Send auto-reply email
        emailjs.send("service_2dasv7f", "template_a6dcw6w", autoReplyData, "j9glKhln_Oj2BLnYu")
          .then((autoReplyResponse) => {
            console.log("Auto-reply sent successfully!", autoReplyResponse.status, autoReplyResponse.text);
          })
          .catch((error) => {
            console.error("Failed to send auto-reply email:", error);
          });

        setLoading(false);
        closeModal(); // Close modal after sending the emails
      })
      .catch((error) => {
        console.error("Failed to send initial email:", error);
        setLoading(false);
      });
};


  

  return (
    <section
      ref={modalRef}
      className={`modal-container ${isOpen ? "visible" : ""}`}
      style={{ display: isOpen ? "flex" : "none" }}
      onClick={closeModal}
    >
      <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
        <svg
          ref={svgRef}
          className="modal-svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon ref={polygonRef} className="modal-polygon" />
        </svg>
        <div ref={contentRef} className="modal-content">
          <div className="close-button" onClick={closeModal}>
            <AiOutlineClose size={22} />
          </div>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              {formFields.map(({ label, type, name, placeholder }, index) => (
                <div key={index} className="col-12">
                  <div className="form-floating">
                    {type === "textarea" ? (
                      <textarea
                        className="form-control"
                        placeholder={placeholder}
                        name={name}
                        value={formData[name] || ""}
                        onChange={handleChange}
                        style={{ height: "150px" }}
                      ></textarea>
                    ) : (
                      <input
                        type={type || "text"}
                        className="form-control"
                        placeholder={placeholder}
                        name={name}
                        value={formData[name] || ""}
                        onChange={handleChange}
                      />
                    )}
                    <label>{label}</label>
                    
                  </div>
                </div>
              ))}
              <div className="col-12">
                <button className="cta-button w-100" type="submit" disabled={loading}>
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Modal;



// import React, { useRef, useLayoutEffect, useState } from "react";
// import { TimelineMax, Power4, Power3, Power2 } from "gsap";
// import { AiOutlineClose } from "react-icons/ai";
// import "./Modal.css";
// import emailjs from "emailjs-com";

// const Modal = ({ 
//   isOpen, 
//   onClose, 
//   formFields = [], 
// }) => {
//   const [formData, setFormData] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [fileData, setFileData] = useState(null); // State to hold file data
//   const modalRef = useRef(null);
//   const contentRef = useRef(null);
//   const polygonRef = useRef(null);
//   const svgRef = useRef(null);
//   const animationRef = useRef(null);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setFileData({
//           name: file.name,
//           content: reader.result.split(",")[1], // Remove base64 prefix
//         });
//       };
//       reader.readAsDataURL(file); // Read file as base64
//     }
//   };

//   const closeModal = () => {
//     if (animationRef.current) {
//       animationRef.current.reverse();
//     }
//   };

//   useLayoutEffect(() => {
//     if (isOpen) {
//       setFormData(
//         formFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
//       );

//       const polygon = polygonRef.current;
//       const svg = svgRef.current;

//       if (svg && polygon) {
//         const points = [
//           createPoint(45, 45),
//           createPoint(55, 45),
//           createPoint(55, 55),
//           createPoint(45, 55),
//         ];

//         animationRef.current = new TimelineMax({
//           paused: true,
//           onStart: () => {
//             if (modalRef.current) {
//               modalRef.current.style.display = "flex";
//             }
//           },
//           onReverseComplete: () => {
//             if (modalRef.current) {
//               modalRef.current.style.display = "none";
//               onClose(); 
//             }
//           },
//         })
//           .to(points[0], 0.3, { x: 15, y: 30, ease: Power4.easeIn }, 0)
//           .to(points[3], 0.3, { x: 5, y: 80, ease: Power2.easeIn }, "-=0.1")
//           .to(points[0], 0.3, { x: 0, y: 0, ease: Power3.easeIn })
//           .to(points[1], 0.3, { x: 100, y: 0, ease: Power2.easeIn }, "-=0.2")
//           .to(points[2], 0.3, { x: 100, y: 100, ease: Power2.easeIn })
//           .to(points[3], 0.3, { x: 0, y: 100, ease: Power2.easeIn }, "-=0.1")
//           .to(modalRef.current, 1, { autoAlpha: 1 }, 0)
//           .to(contentRef.current, 1, { autoAlpha: 1 });

//         function createPoint(x, y) {
//           const point = polygon.points.appendItem(svg.createSVGPoint());
//           point.x = x || 0;
//           point.y = y || 0;
//           return point;
//         }

//         animationRef.current.play();
//       }
//     } else {
//       setFormData({});
//       setFileData(null); // Reset file data when modal closes
//     }
//   }, [isOpen, formFields]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);

//     const emailData = {
//       ...formData,
//       attachment: fileData ? `data:application/pdf;base64,${fileData.content}` : "", // Adjust MIME type as needed
//       attachment_filename: fileData ? fileData.name : "",
//     };

//     emailjs.send("service_pfff2p3", "template_c4umbyp", emailData, "uEbYnxbak5pVxroo-")
//       .then((response) => {
//         console.log("Email sent successfully!", response.status, response.text);
//         setLoading(false);
//         closeModal();
//       })
//       .catch((error) => {
//         console.error("Failed to send email:", error);
//         setLoading(false);
//       });
//   };

//   return (
//     <section
//       ref={modalRef}
//       className={`modal-container ${isOpen ? "visible" : ""}`}
//       style={{ display: isOpen ? "flex" : "none" }}
//       onClick={closeModal}
//     >
//       <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
//         <svg
//           ref={svgRef}
//           className="modal-svg"
//           viewBox="0 0 100 100"
//           preserveAspectRatio="none"
//         >
//           <polygon ref={polygonRef} className="modal-polygon" />
//         </svg>
//         <div ref={contentRef} className="modal-content">
//           <div className="close-button" onClick={closeModal}>
//             <AiOutlineClose size={22} />
//           </div>
//           <h2>Contact Us</h2>
//           <form onSubmit={handleSubmit}>
//             <div className="row g-3">
//               {formFields.map(({ label, type, name, placeholder }, index) => (
//                 <div key={index} className="col-12">
//                   <div className="form-floating">
//                     {type === "textarea" ? (
//                       <textarea
//                         className="form-control"
//                         placeholder={placeholder}
//                         name={name}
//                         value={formData[name] || ""}
//                         onChange={handleChange}
//                         style={{ height: "150px" }}
//                       ></textarea>
//                     ) : (
//                       <input
//                         type={type || "text"}
//                         className="form-control"
//                         placeholder={placeholder}
//                         name={name}
//                         value={formData[name] || ""}
//                         onChange={handleChange}
//                       />
//                     )}
//                     <label>{label}</label>
//                   </div>
//                 </div>
//               ))}
//               {/* File input for resume attachment */}
//               <div className="col-12">
//                 <label>Attach Resume</label>
//                 <input type="file" className="form-control" onChange={handleFileChange} />
//               </div>
//               <div className="col-12">
//                 <button className="cta-button w-100" type="submit" disabled={loading}>
//                   {loading ? "Sending..." : "Send Message"}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Modal;
