// import React from 'react';
// import LocomotiveScroll from 'locomotive-scroll';
// import 'locomotive-scroll/dist/locomotive-scroll.css';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import Banner from '../common/banner/Banner';
// import Footer from '../common/footer/Footer';
// import Services from '../compnent/services/Services';
// import Ceo from '../compnent/ceo/Ceo';
// import Solution from '../compnent/solution/Solution';
// import Launch_product_video from '../compnent/launch_product_videos/Launch_product_video';
// import Why_choose_us from '../compnent/why_choose/Why_choose_us';
// import Use_technologies from '../compnent/use_tech/Use_technologies';
// import Faq from '../compnent/faq/Faq';
// import Project_help from '../compnent/project_help/Project_help';
// import Our_product2 from '../compnent/our_product2/Our_product2';
// import Header from '../common/Header';


// gsap.registerPlugin(ScrollTrigger);

// const Home = () => {
  
 
//   return (
//     <div className="product_container" >
//       {/* <Header /> */}
//       <Banner />
//       <Services />
//       <Our_product2  />
//       <Ceo />
//       <Solution />
//       <Launch_product_video />
//       <Why_choose_us />
//       <Use_technologies />
//       <Faq />
//       <Project_help />
//       <Footer />
//     </div>
//   );
// };

// export default Home;


import React, { useEffect } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Banner from '../common/banner/Banner';
import Footer from '../common/footer/Footer';
import Services from '../compnent/services/Services';
import Ceo from '../compnent/ceo/Ceo';
import Solution from '../compnent/solution/Solution';
import Launch_product_video from '../compnent/launch_product_videos/Launch_product_video';
import Why_choose_us from '../compnent/why_choose/Why_choose_us';
import Use_technologies from '../compnent/use_tech/Use_technologies';
import Faq from '../compnent/faq/Faq';
import Project_help from '../compnent/project_help/Project_help';
import Our_product2 from '../compnent/our_product2/Our_product2';
import Header from '../common/Header';
import Our_experties from '../compnent/our_experties/Our_experties';

gsap.registerPlugin(ScrollTrigger);
const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Home = () => {
 
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="products_container">
      {/* <Header /> */}
      <Banner />
      <Services />
      {/* <Our_product2 /> */}
      {/* <Our_experties/> */}
      <Ceo />
      <Solution />
      <Launch_product_video />
      <Why_choose_us />
      <Use_technologies />
      <Faq />
      <Project_help />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
