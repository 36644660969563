import React, { useEffect } from 'react';
import { GiCheckMark } from "react-icons/gi";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

function Mobile_app_development() {

    useEffect(() => {
        scrollToTop();
    }, []);

    // Arrays for dynamic content
    const expertise = [
        "React Native",
        " Java",
        "Swift & Kotlin",
        " Flutter Expertise",
        "  Agile Methodologies",
        " CI/CD Pipelines",
      
    ];
   
    
   
   
    

    const expertiessections = [
        {
            title: "Mobile Frameworks",
            data: [
                "React Native",
                "Flutter",
                "Ionic",
                "Xamarin",
            ]
        },
        {
            title: "Programming Languages",
            data: [
                "Java",
                "Kotlin",
                "Swift",
                "Dart",
            ]
        },
   
      
        {
            title: "Mobile UI/UX Design Tools",
            data: [
                "Figma",
                "Sketch",
                "Adobe XD",
                "InVision",
            ]
        },
        {
            title: "Deployment Platforms",
            data: [
                "Google Play Store",
                "Apple App Store",
                "Microsoft Store",
            ]
        }
    ];
    

    const sections = [
        {
            title: "Mobile App ",
            subtitle:" Development",
            description: `
             Hiring a full-stack product team is not an economically viable solution for most businesses. However, that is exactly what you need if you want to provide a great mobile experience to your customers. By hiring our full-stack app development team, you will enjoy the benefits of having an in-house team without the trouble of maintaining one on your own. We provide efficient solutions like native apps, web apps, and hybrid apps to help you generate more revenue from your business. .
            `,
            imgSrc: "img/mobile_app.png"
        },
        // Add more sections here if needed
    ];


    return (
        <div>
            <div className="container-xxl py-4" style={{ marginTop: '6%' }}>
                <div className="container px-lg-5">
                    <div className="row g-5 mb-5 align-items-center">
                    {sections.map((section, index) => (
                            <React.Fragment key={index}>
                                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                                    <div className="image-section">
                                        <img className="employee-image" src={section.imgSrc} alt={section.title} />
                                    </div>
                                </div>
                                <div className="col-lg-6 fadeInUp custom-box" data-wow-delay="0.2s">
                                    <div className="services_heading mb-4">
                                        {section.title}
                                        <span className='highlight'> {section.subtitle} </span>
                                    </div>
                                    <div className="services_subheading mb-4">
                                        {section.description}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="row g-5 mb-5 align-items-center">
                        <div className="col-md-6 custom-box">
                            <div style={styles.heading}>EXPERTISE</div>
                            {expertise.map((item, index) => (
                                <div style={styles.item} key={index}>
                                    <GiCheckMark style={styles.icon} />
                                    <div>
                                        <div style={styles.title}>{item}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6 wow zoomIn" data-wow-delay="0.5s">
                            <div className="image-section text-center">
                                <img className="employee-image" src="img/cr2.png" alt="Employee" style={styles.image} />
                            </div>
                        </div>

                        <div className="benefits-container">
                        {expertiessections.map((section, index) => (
                            <div key={index} className="benefit-card">
                                <div style={styles.title}>{section.title}</div>
                                <ul className="services_subheading">
                                    {section.data.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mobile_app_development;

const styles = {
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '8px',
    },
    icon: {
        marginRight: '15px',
        fontSize: '20px',
        color: '#000',
    },
    title: {
        fontSize: '18px',
        fontWeight: '600',
        margin: '0px',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
    },
};

  