import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";



const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({
        home: false,
        services: false
    });

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Remove hero-anime class on component mount
        document.body.classList.remove("hero-anime");
    }, []);

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }
    }, [isDarkMode]);

    const handleMouseEnterLeave = (menuItem) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [menuItem]: !prevState[menuItem]
        }));
    };

    return (
        <>
            <div className=" top_header_menu container-fluid  ">
        <div className="row py-2 px-lg-5">
            <div className="col-lg-6  text-lg-left mb-2 mb-lg-0">
                <div className="d-inline-flex align-items-center text-white" style={{fontSize: "15px"}}>
                    <small><i className="fa fa-phone-alt " ></i>+91 6290382261</small>
                 
                    <small><i className="fa fa-envelope mr-2"style={{margin:" 0px 10px"}}></i>hr.roboanalytics@gmail.com</small>
                </div>
            </div>
            <div className="col-lg-6 text-end text-lg-right">
                <div className="d-inline-flex align-items-center">
                    {/* <a className="text-white px-2" href="">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a className="text-white px-2" href="">
                        <i className="fab fa-twitter"></i>
                    </a> */}
                    <a className="text-white px-2" href="https://www.linkedin.com/in/abhishek-bhattacharjee-78873a4b/" target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                    </a>
                    {/* <a className="text-white px-2" href="">
                        <i className="fab fa-instagram"></i>
                    </a> */}
                    <a className="text-white px-2" href="https://www.youtube.com/channel/UCXQa4S8DQqzPVYt4YgJRdJw" target="_blank">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
       </div>
            <div className="hero-anime">
                {/* Navigation Bar */}
                <div className={`navigation-wrap bg-light start-header ${isScrolled ? "scroll-on" : "start-style"}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-md navbar-light">
                                    <Link className="navbar-brand" to="/" >
                                        <img src="/img/robo_logo.png" alt="robo analytics" />
                                    </Link>

                                    <div className="collapse navbar-collapse" style={{ justifyContent: 'end' }}>
                                        <ul className="navbar-nav ml-auto py-4 py-md-0" style={{ gap: '20px' }}>
                                            {/* Home dropdown */}
                                            <li
                                                className={`nav-item pl-4 pl-md-0 ml-0 ml-md-4 ${dropdownOpen.home ? 'show' : ''}`}
                                                onMouseEnter={() => handleMouseEnterLeave('home')}
                                                onMouseLeave={() => handleMouseEnterLeave('home')}
                                            >
                                                <Link className="nav-link dropdown-toggle" to="/" role="button">
                                                    Home
                                                </Link>
                                                {/* <div className={`dropdown-menu ${dropdownOpen.home ? 'show' : ''}`}>
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div> */}
                                            </li>
                                            {/* Portfolio */}
                                            <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                <a className="nav-link" href="/portfolio">Portfolio</a>
                                            </li>
                                            {/* Agency */}
                                            <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                <Link className="nav-link" to="/career">Career</Link>
                                            </li>
                                            {/* Services dropdown */}
                                            <li
                                                className={`nav-item pl-4 pl-md-0 ml-0 ml-md-4 ${dropdownOpen.services ? 'show' : ''}`}
                                                onMouseEnter={() => handleMouseEnterLeave('services')}
                                                onMouseLeave={() => handleMouseEnterLeave('services')}
                                            >
                                                <Link className="nav-link dropdown-toggle" to="/our-services" role="button">
                                                    Services
                                                </Link>
                                                {/* <div className={`dropdown-menu ${dropdownOpen.services ? 'show' : ''}`}>
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div> */}
                                            </li>
                                            {/* Journal */}
                                            {/* <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                <a className="nav-link" href="#">Blogs</a>
                                            </li> */}
                                            {/* Contact */}
                                            <li className="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                                <Link className="nav-link" to="/contact">Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Section */}
                {/* <div className="section full-height">
                    <div className="absolute-center">
                        <div className="section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h1>
                                            <span>R</span><span>O</span><span>B</span><span>O</span><span></span> <span>A</span><span>N</span><span>A</span><span>L</span><span>Y</span><span>T</span><span>I</span><span>C</span><span>S</span>
                                            <br />
                                            <span>s</span><span>o</span><span>f</span><span>t</span><span>w</span>e<span>r</span><span>e</span> <span>c</span><span>o</span>m<span>p</span><span>a</span><span>n</span><span>y</span>
                                        </h1>
                                        <p>Scroll for nav animation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section mt-5">
                            <div className="container">
                                <button id="switch" className={isDarkMode ? "switched" : ""} onClick={toggleDarkMode}>
                                    Switch to {isDarkMode ? "Light" : "Dark"} Mode
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
             
            </div>
        </>
    );
};

export default Header;
