import React, { useState } from 'react'
import './faq.css'

const Faq = () => {
    const [selected, setSelected] = useState(0);


    const toggle = (i) => {
        // Toggle the clicked FAQ, including the first one
        setSelected(selected === i ? null : i);
    };

    const faqs = [
        { question: 'Who we are?', answer: 'We are the leading USA-based Web App Design & Development Company, creating scalable web applications, integrated with modern design frameworks and user-centric UI/UX. Hire web app developers to get top-notch quality web development services to boost performance. Explore more about us, and connect with our tech experts to have better insights.' },
        { question: 'What services do Robo Analytics provide?', answer: 'We offer digital marketing, web app development, UI/UX designs, AI, ML apps, cloud-based, graphic design, blockchain, and mobile app development.' },
        { question: 'What are the industries you work for?', answer: 'Robo Analytics, a top-ranked Web App Design & Development Company that delivers the following services with a well-versed and experienced team of experts: Real Estate, Healthcare, FoodTech, Travel & Hospitality, Automotive, Education, Retail & E-Commerce, Transportation, FinTech.' },
        { question: 'What approaches do you follow to deliver the project?', answer: 'Our team of web app developers adheres to the Agile Methodology that helps to select the right set of software for your business project, depending on the scope, and requirements, and supports us to deliver high-quality custom web app development solutions. 1-Ideation, 2-Development, 3-Testing, 4-Deployment, 5-Operations' },
        { question: 'How long does it take to develop and design a web app or mobile app?', answer: 'Our operations are time-bound and yet depending on the complexity, scope, and size of the project, the time frame can be decided. Through the help of our dedicated team of experts, we prioritize executing each project by ensuring quality and meeting all the requirements of the clients as well.' },
        { question: 'How much cost does it take for web app development?', answer: 'Depending on the project’s complexity, scope, size, and other essential factors help to decide the cost of web app development. To know the details of the cost structure for web app development, feel free to connect with our tech experts.' }
    ];
  return (
    <div>
        <div className="faq-container ">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow zoomIn" data-wow-delay="0.5s">
                        <div className='services_heading'>Frequently  <span className='highlight'>Asked</span>  Questions</div>
                        <div className='services_subheading'>Frequently asked question (FAQ) pages to find answers.</div>
                        {/* <img src="img/faq.jpg" style={{ width: "40vw", marginLeft: "-65px" }} /> */}
                    </div>

                    <div className="col-lg-6 fadeInUp" data-wow-delay="0.2s">
                        <div className="faq">
                            {faqs.map((item, i) => (
                                <div className="faq-item" key={i}>
                                    <div className="faq-question" onClick={() => toggle(i)}>
                                        <div className='subheading mb-2'>{item.question}</div>
                                        <span style={{ fontSize: "24px" }}>{selected === i ? '-' : '+'}</span>
                                    </div>
                                    <div className={selected === i ? 'faq-answer show ' : 'faq-answer'}>
                                        <div className='services_subheading'>{item.answer}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Faq
