import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './use_technology.css';

const UseTechnologies = () => {
    const [value, setValue] = useState('1'); // Default to first tab (UI/UX)
  
    const theme = createTheme({
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontWeight: '700',
                        color: '#fff',
                        '&.Mui-selected': {
                            color: '#FF6B35',
                        },
                    },
                },
            },
        },
    });

    const tabItems = [
        {
            label: 'AI/ML',
            value: '1',
            items: [
                { imgSrc: '/img/use_tech_icon/Ai.png', description: 'AI ' },
                { imgSrc: '/img/use_tech_icon/prompt.png', description: 'Prompt' },
                { imgSrc: '/img/use_tech_icon/ml.png', description: 'ML' },
                { imgSrc: '/img/use_tech_icon/ai4.png', description: 'AI Content 4' },
                { imgSrc: '/img/use_tech_icon/ai5.png', description: 'AI Content 5' },
            ],
        },
        {
            label: 'UI/UX',
            value: '2',
            items: [
                { imgSrc: '/img/use_tech_icon/Figma.png', description: 'Figma' },
                { imgSrc: '/img/use_tech_icon/AdobeXd.png', description: 'AdobeXD' },
                { imgSrc: '/img/use_tech_icon/AdobeAI.png', description: 'adobeAI' },
                { imgSrc: '/img/use_tech_icon/AdobePhotoshop.png', description: 'photoshop' },
                { imgSrc: '/img/use_tech_icon/Sketch.png', description: 'Sketch' },
            ],
        },
        {
            label: 'Mobile',
            value: '3',
            items: [
                { imgSrc: '/img/use_tech_icon/ReactNative.png', description: 'React Native' },
                { imgSrc: '/img/use_tech_icon/swift.png', description: 'Swift' },
                { imgSrc: '/img/use_tech_icon/Firebase.png', description: 'Firebase' },
                { imgSrc: '/img/use_tech_icon/Kotlin.png', description: 'Kotlin' },
                { imgSrc: '/img/use_tech_icon/AndroidStudio.png', description: 'AndroidStudio' },
            ],
        },
        {
            label: 'Frontend',
            value: '4',
            items: [
                { imgSrc: '/img/use_tech_icon/HTML.png', description: 'HTML' },
                { imgSrc: '/img/use_tech_icon/css.png', description: 'CSS' },
                { imgSrc: '/img/use_tech_icon/js.png', description: 'Javascript' },
                { imgSrc: '/img/use_tech_icon/ReactNative.png', description: 'React Js' },
                { imgSrc: '/img/use_tech_icon/ReactNative.png', description: 'Next Js' },
            ],
        },
        {
            label: 'Backend',
            value: '5',
            items: [
                { imgSrc: '/img/use_tech_icon/PHP.png', description: 'PHP' },
                { imgSrc: '/img/use_tech_icon/Java.png', description: 'Java' },
                { imgSrc: '/img/use_tech_icon/Python.png', description: 'Python' },
                { imgSrc: '/img/use_tech_icon/NodeJS.png', description: 'NodeJS' },
                { imgSrc: '/img/use_tech_icon/CSharp.png', description: 'C#' },
            ],
        },
        {
            label: 'Databases',
            value: '6',
            items: [
                { imgSrc: '/img/use_tech_icon/MySQL.png', description: 'MySQL' },
                { imgSrc: '/img/use_tech_icon/SQLite.png', description: 'SQLite' },
                { imgSrc: '/img/use_tech_icon/MongoDB.png', description: 'MongoDB' },
                { imgSrc: '/img/use_tech_icon/OracleDB.png', description: 'OracleDB' },
                { imgSrc: '/img/use_tech_icon/PostgreSQL.png', description: 'PostgreSQL' },
            ],
        },
        {
            label: 'Framework',
            value: '7',
            items: [
                { imgSrc: '/img/use_tech_icon/DJango.png', description: 'DJango' },
                { imgSrc: '/img/use_tech_icon/Flask.png', description: 'Flask' },
                { imgSrc: '/img/use_tech_icon/SpringMVC.png', description: 'SpringMVC' },
                { imgSrc: '/img/use_tech_icon/laravel.png', description: 'laravel' },
                { imgSrc: '/img/use_tech_icon/codeigniter.png', description: 'Codeigniter' },
            ],
        },
      
    ];

    return (
        <div>
            <div className="use_tech_container container-xxl testimonial-it py-5 my-5 wow fadeInUp" data-wow-delay="0.1s">
                <div style={{ textAlign: 'center', marginBottom: '10px', color: '#fff', display: 'flex', justifyContent: 'center' }}>
                    <div className="services_heading" style={{ display: 'flex' }}>
                        We use technology that is
                        <span className="highlight" style={{ marginLeft: '10px', marginRight: '10px' }}>built</span>
                        to last
                    </div>
                </div>

                <div className="container py-5 px-lg-5 use_tech">
                    <ThemeProvider theme={theme}>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-around' }}>
                                    <TabList
                                        aria-label="tech tabs example"
                                        sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 2, textAlign: 'center' }}
                                    >
                                        {tabItems.map((tab) => (
                                            <Tab
                                                key={tab.value}
                                                label={tab.label}
                                                value={tab.value}
                                                onMouseEnter={() => setValue(tab.value)} // Update tab on hover
                                            />
                                        ))}
                                    </TabList>
                                </Box>

                                {tabItems.map((tab) => (
                                    <TabPanel key={tab.value} value={tab.value}>
                                        <div className="testimonial-item bg-transparent rounded p-4" style={{ backgroundColor: '#fff' }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                flexWrap: 'wrap',
                                                gap: '20px'
                                            }}>
                                                {tab.items.map((item, index) => (
                                                    <div key={index} style={{ textAlign: 'center', marginBottom: '20px', padding: '32px' }} className="use_tab">
                                                        <img
                                                            className="img-fluid"
                                                            src={item.imgSrc}
                                                            alt={tab.label}
                                                            style={{
                                                                width: '45px',
                                                                height: '45px',
                                                                marginBottom: '10px',
                                                                objectFit: 'cover',
                                                                filter: 'grayscale(100%) brightness(0.4)',
                                                            }}
                                                        />
                                                        <div style={{ fontSize: '16px', color: '#666666' }}>{item.description}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </TabPanel>
                                ))}
                            </TabContext>
                        </Box>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    );
};

export default UseTechnologies;
